import React from 'react'
import styled, { css } from 'styled-components'
import { TopPanel as MasterTopPanel } from '@style-space/components/src/TopPanel'

const SMasterTopPanel = styled(MasterTopPanel)(
  ({ theme }) => css`
    &.transparent {
      .top-panel-background {
        background-color: transparent;
      }
      &.shadow {
        .top-panel-background {
          background-color: ${theme.color.white};
        }
      }
    }
  `,
)

const LOGO_MOBILE = '/logo_gray.svg'
const LOGO = '/logo_gray_long.svg'

type Props = {
  transparent?: boolean
  className?: string
}

export const TopPanel: React.FC<Props> = ({ transparent, className = '' }) => {
  const transparentClass = transparent ? 'transparent' : ''

  return (
    <>
      <SMasterTopPanel
        className={`${className} ${transparentClass}`}
        logo={LOGO}
        mobileLogo={LOGO_MOBILE}
      ></SMasterTopPanel>
    </>
  )
}
